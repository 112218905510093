import styled from 'styled-components';

const getIcon = (color) => {
  const fillColor = encodeURIComponent(color);

  return `url("data:image/svg+xml;charset=utf-8,%3Csvg fill='${fillColor}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z'/%3E%3C/svg%3E")`;
};

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 1rem 1.5rem;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin: 0;
  }
`;

const Label = styled.label`
  font-size: 1.2rem;
  margin-right: 0.5rem;
`;

const SearchInput = styled.input`
  border: solid 2px var(--theme-color-grey3);
  flex: 1;
  font-size: 1.2rem;
  margin-right: 0.5rem;
  padding: 0.25rem;
`;

const Button = styled.button`
  background: transparent no-repeat center center;
  background-image: var(--theme-color-text);
  background-size: 18px 18px;
  border: 0;
  height: 25px;
  width: 25px;
`;

const SearchBar = () => {
  return (
    <Wrapper>
      <Label className="visually-hidden" htmlFor="search">
        Search
      </Label>
      <SearchInput id="search" type="search" />
      <Button>
        <span className="visually-hidden">Search</span>
      </Button>
    </Wrapper>
  );
};

export default SearchBar;

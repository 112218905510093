import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  z-index: 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    background: var(--theme-color-grey1);
    height: auto;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  background: var(--theme-color-grey1);
  box-shadow: inset 8px 0 0 0 var(--theme-color-text);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate3d(calc(-100vw + 50px), 0, 0);
  width: calc(100% - 50px);
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.lg} {
    box-shadow: none;
    height: auto;
    margin: 0 auto 2rem;
    max-width: ${(props) => props.theme.maxWidth};
    position: static;
    top: auto;
    transform: none;
    width: 100%;
  }
`;

type OffCanvasProps = {
  children: React.ReactNode;
};

const OffCanvas = ({ children }: OffCanvasProps) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default OffCanvas;

import styled from 'styled-components';

import { event, Event } from '@utils/gtag';

const Button = styled.div`
  border: none;
  background: var(--theme-color-always-dark-overlay);
  border-radius: 50px;
  bottom: 20px;
  color: var(--theme-color-always-light);
  cursor: pointer;
  font-size: 0;
  padding: 0.8rem 1rem 1.2rem;
  position: fixed;
  right: 20px;
  z-index: 2;

  &:hover {
    background: var(--theme-color-always-dark);
  }

  .arrow {
    fill: none;
    stroke: var(--theme-color-always-light);
    stroke-width: 1px;
    transform: rotate(-90deg);
  }
`;

type ScrollToTopProps = {
  analytics?: Event;
};

const ScrollToTop = ({ analytics }: ScrollToTopProps) => {
  const scrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });
    event(analytics || { action: 'click', category: 'scroll', label: 'top' });
  };

  return (
    <Button onClick={scrollToTop}>
      <svg
        className="arrow"
        width="18"
        height="18"
        viewBox="0 0 10 10"
        aria-hidden="true"
      >
        <g fillRule="evenodd">
          <path className="tip" d="M1 1l4 4-4 4"></path>
        </g>
      </svg>
    </Button>
  );
};

export default ScrollToTop;

import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from '@styles/index';

const ThemeWrapper = ({ children, theme }) => {
  return (
    <ThemeProvider theme={theme == 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;

import { memo } from 'react';
import Link from 'next/link';

import * as S from './Logo.sc';

type Logo = {
  size: 'default' | 'tiny';
  title: string;
};

const Logo = ({ size = 'default', title }) => {
  return (
    <S.Wrapper data-size={size}>
      <Link href="/" passHref legacyBehavior>
        <S.Anchor>
          <S.Title data-size={size}>{title}</S.Title>
        </S.Anchor>
      </Link>
    </S.Wrapper>
  );
};

export default memo(Logo);

import { memo } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const Container = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: ${(props) => props.theme.maxContentWidth};
    padding: 0 1.5rem;
  }
`;

const ListItem = styled.li`
  margin: 0 0 4px;
  padding: 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin: 0;
    text-align: center;
    width: 20%;
  }
`;

const Anchor = styled.a`
  border-left: solid 8px transparent;
  color: var(--theme-color-text);
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-decoration: none;

  @media ${(props) => props.theme.breakpoints.lg} {
    border-bottom: solid 4px transparent;
    border-left: none;
    padding: 0.5rem 0;
  }

  &:hover {
    border-color: var(--theme-color-text);
    color: var(--theme-color-background);
  }
`;

const HomeAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-home);

  &:hover {
    background: var(--theme-color-section-home);
  }
`;

const NewsAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-news);

  &:hover {
    background: var(--theme-color-section-news);
  }
`;

const ReviewsAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-reviews);

  &:hover {
    background: var(--theme-color-section-reviews);
  }
`;

const FeaturesAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-features);

  &:hover {
    background: var(--theme-color-section-features);
  }
`;

const CollectionsAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-collections);

  &:hover {
    background: var(--theme-color-section-collections);
  }
`;

const CommunityAnchor = styled(Anchor)`
  border-color: var(--theme-color-section-community);

  &:hover {
    background: var(--theme-color-section-community);
  }
`;

const Navigation = ({ featureFlags }) => {
  const NavItems = [
    {
      component: HomeAnchor,
      text: 'Home',
      url: '/',
      enabled: true,
    },
    {
      component: NewsAnchor,
      text: 'News',
      url: '/news',
      enabled: true,
    },
    {
      component: ReviewsAnchor,
      text: 'Reviews',
      url: '/reviews',
      enabled: true,
    },
    {
      component: FeaturesAnchor,
      text: 'Features',
      url: '/features',
      enabled: true,
    },
    {
      component: CollectionsAnchor,
      text: 'Collections',
      url: '/collections',
      enabled: featureFlags?.collections,
    },
    {
      component: CommunityAnchor,
      text: 'Community',
      url: '/community',
      enabled: featureFlags?.community,
    },
  ];

  return (
    <nav>
      <Container>
        {NavItems.filter((item) => !!item.enabled).map((item) => {
          const Component = item.component;
          return (
            <ListItem key={item.text}>
              <Link href={item.url} passHref legacyBehavior>
                <Component>{item.text}</Component>
              </Link>
            </ListItem>
          );
        })}
      </Container>
    </nav>
  );
};

export default memo(Navigation);

import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Header from '@components/Header/Header';
import ThemeWrapper from '@components/ThemeWrapper/ThemeWrapper';

import themeSection from '@styles/includes/themeSection';
import ScrollToTop from '@components/ScrollToTop/ScrollToTop';

const CookieBar = dynamic(() => import('@components/CookieBar/CookieBar'));
const Footer = dynamic(() => import('@components/Footer/Footer'));

const Wrapper = styled.div<{ $isMenuOpen: boolean }>`
  min-height: 100vh;
  margin: 0;
  overflow: ${(props) => (props.$isMenuOpen ? 'initial' : 'hidden')};
  transform: ${(props) =>
    props.$isMenuOpen
      ? 'translate3d(calc(100vw - 50px), 0, 0);'
      : 'translate3d(0, 0, 0)'};
  transition: transform 150ms ease-out;

  ${themeSection}

  @media ${(props) => props.theme.breakpoints.lg} {
    transform: translate3d(0, 0, 0);
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxWidth};
  padding: 0 0.5rem;
`;

const Main = styled.main`
  padding: 2rem 0;
`;

type LayoutProps = {
  children: React.ReactNode;
  featureFlags: any;
  pageDescription?: string;
  pageImage?: string;
  pageTitle: string;
  pageType: 'article' | 'profile' | 'website';
  schema?: string;
  section: Section;
  siteTitle: string;
  url: string;
};

const Layout = ({
  children,
  featureFlags,
  pageDescription,
  pageImage,
  pageTitle,
  pageType,
  schema,
  section = 'Home',
  siteTitle,
  url,
}: LayoutProps) => {
  const [theme, setTheme] = useState('light');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleTheme = () => {
    theme == 'light' ? setTheme('dark') : setTheme('light');
  };

  const [showScrollButton, setShowScrollButton] = useState(false);

  const fullUrl = process.env.SITE_URL + url;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    });
  }, []);

  return (
    <ThemeWrapper theme={theme}>
      <Wrapper $isMenuOpen={isMenuOpen} data-section={section.toLowerCase()}>
        <Head>
          <title>{`${pageTitle} - ${siteTitle}`}</title>

          <meta name="description" content={pageDescription} />
          <link rel="canonical" href={fullUrl} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          <link
            rel="preload"
            href="/fonts/inter.var.woff2"
            as="font"
            crossOrigin=""
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />

          <meta property="og:description" content={pageDescription} />
          {pageImage ? <meta property="og:image" content={pageImage} /> : null}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:type" content={pageType} />
          <meta property="og:url" content={fullUrl} />

          {schema ? (
            <Script
              id="schema"
              type="application/ld_json"
              dangerouslySetInnerHTML={{ __html: schema }}
            />
          ) : null}
        </Head>

        <Header
          featureFlags={featureFlags}
          isMenuOpen={isMenuOpen}
          theme={theme}
          title={siteTitle}
          toggleMenu={toggleMenu}
          toggleTheme={toggleTheme}
        />
        <Container>
          <Main>{children}</Main>
        </Container>
        <Footer title={siteTitle} />
      </Wrapper>

      {showScrollButton && (
        <ScrollToTop
          analytics={{
            category: section,
            action: 'scroll_to_top',
            label: pageTitle,
          }}
        />
      )}
      <CookieBar />
    </ThemeWrapper>
  );
};

export default Layout;

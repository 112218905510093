import 'modern-css-reset/dist/reset.min.css';

import GlobalStyles from './global';
import theme from './theme';

const { lightColors, darkColors, ...settings } = theme;

const lightTheme = {
  colors: lightColors,
  ...settings,
};

const darkTheme = {
  colors: darkColors,
  ...settings,
};

export { GlobalStyles, lightTheme, darkTheme };

import { css } from 'styled-components';

const section = css`
  &[data-section='home'] {
    --theme-color-section: var(--theme-color-section-home);
  }
  &[data-section='news'] {
    --theme-color-section: var(--theme-color-section-news);
  }
  &[data-section='reviews'] {
    --theme-color-section: var(--theme-color-section-reviews);
  }
  &[data-section='features'] {
    --theme-color-section: var(--theme-color-section-features);
  }
  &[data-section='collections'] {
    --theme-color-section: var(--theme-color-section-collections);
  }
  &[data-section='community'] {
    --theme-color-section: var(--theme-color-section-community);
  }
`;

export default section;

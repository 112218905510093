const breakpoints = {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
};

const fonts = {
  header:
    'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  body: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
};

const maxContentWidth = '800px';
const maxHeaderWidth = '1000px';
const maxWidth = '1440px';

const constantColors = {
  alwaysDark: '#333',
  alwaysLight: '#fff',
  cancel: '#A9A9A9',
  cancelHover: '#B2BEB5',
  confirm: '#008558',
  confirmHover: '#00a36c',
  remove: '#9a2a2a',
  removeHover: '#c04000',
};

const sectionColors = {
  home: '#ff005c',
  news: '#ffbf00',
  reviews: '#00e676',
  features: '#00b0ff',
  collections: '#006fff',
  community: '#ab47bc',
};

// Color definitions
const lightColors = {
  background: '#fff',
  grey: '#eef3f9',
  grey2: '#d2d7de',
  grey3: '#666',
  link: '#004fa0',
  title: '#000',
  text: '#464646',
  ...sectionColors,
  ...constantColors,
};

const darkColors = {
  background: '#22272e',
  grey: '#3f474e',
  grey2: '#5f6c77',
  grey3: '#9dacb9',
  link: '#7ebeff',
  title: '#fff',
  text: '#adbac7',
  ...sectionColors,
  ...constantColors,
};

const theme = {
  breakpoints,
  fonts,
  maxContentWidth,
  maxHeaderWidth,
  maxWidth,
  lightColors,
  darkColors,
};

export default theme;

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    ${({ theme }) => `
    --theme-color-background: ${theme.colors?.background};
    --theme-color-text: ${theme.colors?.text};
    --theme-color-title: ${theme.colors?.title};
    --theme-color-link: ${theme.colors?.link};
    --theme-color-grey1: ${theme.colors?.grey};
    --theme-color-grey2: ${theme.colors?.grey2};
    --theme-color-grey3: ${theme.colors?.grey3};
    --theme-color-button-secondary: ${theme.colors?.cancel};
    --theme-color-button-secondary-hover: ${theme.colors?.cancelHover};
    --theme-color-button-confirm: ${theme.colors?.confirm};
    --theme-color-button-confirm-hover: ${theme.colors?.confirmHover};
    --theme-color-button-remove: ${theme.colors?.remove};
    --theme-color-button-remove-hover: ${theme.colors?.removeHover};
    --theme-color-section-home: ${theme.colors?.home};
    --theme-color-section-news: ${theme.colors?.news};
    --theme-color-section-reviews: ${theme.colors?.reviews};
    --theme-color-section-features: ${theme.colors?.features};
    --theme-color-section-collections: ${theme.colors?.collections};
    --theme-color-section-community: ${theme.colors?.community};
    --theme-color-always-light: #fff;
    --theme-color-always-dark-overlay: rgba(0, 0, 0, .6);
    --theme-color-always-dark: #333;
    --theme-font-header: ${theme.fonts.header};
    --theme-font-body: ${theme.fonts.body};
    `}



  }


  @font-face {
    font-family: 'inter';
    src: url('/fonts/inter.var.woff2') format('woff2 supports variations'),
       url('/fonts/inter.var.woff2') format('woff2-variations');
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
  }

  body {
    background: var(--theme-color-background);
    color: var(--theme-color-text);
  }

  html {
    font-family: var(--theme-font-body);
    font-feature-settings: "ss03","zero","cv03","cv04","cv08","cv09","cv11";
    font-size: 14px;
    font-weight: 250;
  }xw

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--theme-color-title);
    font-family: var(--theme-font-header);
    font-feature-settings: "ss03","cv08","cv11";
    margin: 0 0 2rem;
  }

  p {
    font-size: 1.2rem;
    margin: 0 0 1rem;

    @media ${(props) => props.theme.breakpoints.md} {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }

    @media ${(props) => props.theme.breakpoints.lg} {
      margin-bottom: 2rem;
    }
  }

  a {
    color: var(--theme-color-link);
  }

  input {
    background-color: var(--theme-color-background);
    color: var(--theme-color-text);
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  ::selection {
    background: var(--theme-color-section-home);
    color: var(--theme-color-background);
  }
`;

export default GlobalStyles;

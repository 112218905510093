import styled from 'styled-components';

export const Wrapper = styled.header`
  background-color: var(--theme-color-grey1);
  padding: 0.5rem 0 0.25rem;
  position: relative;

  @media ${(props) => props.theme.breakpoints.lg} {
    background-color: var(--theme-color-background);
    background-image: radial-gradient(
        var(--theme-color-grey1) 20%,
        transparent 0
      ),
      radial-gradient(var(--theme-color-grey1) 20%, transparent 0);
    background-size: 24px 24px, 24px 24px;
    background-position: 0 0, 12px 12px;
    background-repeat: repeat, repeat;
    padding: 1rem 0 0;
  }
`;

export const Container = styled.div`
  margin: 0 auto 0.5rem;
  max-width: ${(props) => props.theme.maxWidth};
  padding: 0.5rem;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.sm} {
    margin: 0 auto 1rem;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 1rem;
  }
`;

export const ThemeOptions = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`;

export const SearchOptions = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`;

import dynamic from 'next/dynamic';
import { memo } from 'react';

import * as S from './Header.sc';

import Logo from '@components/Logo/Logo';
import Navigation from '@components/Navigation/Navigation';
import Menu from '@components/Menu/Menu';
import SearchBar from '@components/SearchBar/SearchBar';

import useWindowDimensions from '@hooks/useWindowDimensions';

const MenuToggle = dynamic(
  () => import('@components/Menu/MenuToggle/MenuToggle')
);
const ThemeToggle = dynamic(
  () => import('@components/ThemeToggle/ThemeToggle')
);
const UserHeaderBar = dynamic(
  () => import('@components/UserHeaderBar/UserHeaderBar')
);

type HeaderProps = {
  featureFlags: any;
  isMenuOpen: boolean;
  theme: string;
  title: string;
  toggleMenu: () => void;
  toggleTheme: () => void;
};

const Header = ({
  featureFlags,
  isMenuOpen,
  theme,
  title,
  toggleMenu,
  toggleTheme,
}: HeaderProps) => {
  const { width } = useWindowDimensions();

  return (
    <S.Wrapper>
      <S.Container>
        <Logo title={title} />
      </S.Container>
      <Menu>
        <S.SearchOptions>
          {featureFlags.search ? <SearchBar /> : null}

          {featureFlags.community ? <UserHeaderBar /> : null}
        </S.SearchOptions>
        <Navigation featureFlags={featureFlags} />
        <S.ThemeOptions>
          {featureFlags.darkMode ? (
            <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
          ) : null}
        </S.ThemeOptions>
      </Menu>
      {width && width < 1024 ? (
        <MenuToggle isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      ) : null}
    </S.Wrapper>
  );
};

export default memo(Header);

import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--theme-color-background);
  border: solid 3px var(--theme-color-text);
  box-shadow: 3px 3px 0 0 var(--theme-color-section);
  display: inline-block;
  margin: 0 auto 4px;
  position: relative;

  @media ${(props) => props.theme.breakpoints.sm} {
    border-width: 4px;
    box-shadow: 6px 6px 0 var(--theme-color-section);
    margin: 0;
    margin-bottom: 4px;

    &[data-size='tiny'] {
      border-width: 3px;
      box-shadow: 3px 3px 0 var(--theme-color-section);
    }
  }
`;

export const Anchor = styled.a`
  color: var(--theme-color-text);
  text-decoration: none;

  &:hover {
    color: var(--theme-color-section-home);
  }
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.014em;
  line-height: 1;
  margin-bottom: 0;
  padding: 0.6rem;

  &[data-size='tiny'] {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 3rem;
    padding: 1rem;

    &[data-size='tiny'] {
      font-size: 1.4rem;
    }
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 4rem;
    padding: 1.5rem;
  }
`;
